import { Box } from "@mui/material";
import * as React from "react";
import VerticalMenu from "../../components/VerticalMenu";
import HorizontalMenu from "../../components/HorizontalMenu";
import Container from "@mui/material/Container";
import Helmet from "react-helmet";
import favicon from "../../images/favicon.ico";
import PageTitle from "../../components/PageComponents/PageTitle";
import Paragraph from "../../components/PageComponents/Paragraph";
import ParagraphTitle from "../../components/PageComponents/ParagraphTitle";
import SupportButton from "../../components/SupportButton";
import ClickableIntegrationLogo from "../../components/ClickableIntegrationLogo";
import { pageStyles, pageContent } from "../../styleConstants";

const logoBox = {
  marginBottom: "20px",
};

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <HorizontalMenu />
      <Box sx={pageContent}>
        <VerticalMenu page={13} />
        <Container maxWidth="md" sx={{ wordWrap: "break-word" }}>
          <PageTitle text="Integrations" />
          <Paragraph text="Integrate our app with multiple tools for seamless collaboration, streamlined processes, and increased productivity. Simplify your life and unlock endless possibilities with effortless connectivity across platforms." />
          <Box sx={logoBox}>
            <ParagraphTitle text="Our available integrations" />
            <ClickableIntegrationLogo link="/integrations/mapbox" />
          </Box>
          <Paragraph text="We are constantly expanding our integration offerings to provide you with even more options for enhancing your workflow. Our dedicated team is hard at work, developing new integrations to seamlessly connect our app with additional tools. Stay tuned for the latest updates as we strive to empower you with a continuously growing ecosystem of integrations, making your experience even more versatile and efficient." />
          <ParagraphTitle text="Need help" />
          <Paragraph
            text="For questions and inquiries, you are more than welcome to contact
            GLOBHE's tech support."
          />
          <SupportButton />
        </Container>
      </Box>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GLOBHE&apos;s developers docs</title>
        <link rel="icon" href={favicon} />
      </Helmet>
    </main>
  );
};

export default IndexPage;
