import React from "react";
import Box from "@mui/material/Box";
import { Link } from "gatsby";
import mapboxLogo from "../images/mapbox.png";
import PropTypes from "prop-types";
import theme from "../theme";

const styles = {
  image: {
    cursor: "pointer",
    width: "160px",
    padding: "20px",
    "&:hover": {
      backgroundColor: theme.palette.secondary.paleLavender,
    },
  },
};
const ClickableIntegrationLogo = ({ link }) => {
  return (
    <Link to={link}>
      <Box
        sx={styles.image}
        component="img"
        src={mapboxLogo}
        alt="Clickable Image"
      />
    </Link>
  );
};
ClickableIntegrationLogo.propTypes = {
  link: PropTypes.string.isRequired,
};

export default ClickableIntegrationLogo;
